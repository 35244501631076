<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="3"
        sm="6"
        order="1"
        class="align-self-end"
      >
        <statistics-card-with-images v-if="sum>0"
          :avatar="ratingsOptions.avatar"
          :avatar-width="ratingsOptions.avatarWidth"
          :change="ratingsOptions.change"
          :chip-color="ratingsOptions.chipColor"
          :chip-text="timeLast"
          :statistics="sum"
          stat-title="Доход за день"
          :count="count"
        ></statistics-card-with-images>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6"
        sm="12"
        order="6"
      >
        <crm-total-sales></crm-total-sales>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import StatisticsCardWithImages from '@core/components/statistics-card/StatisticsCardWithImages.vue'
import StatisticsCardAreaChart from '@core/components/statistics-card/StatisticsCardAreaChart.vue'
import moment from 'moment'
import StatisticsCardVertical from '@/@core/components/statistics-card/StatisticsCardVertical.vue'

// icons
import { mdiLabelVariantOutline } from '@mdi/js'
// demos
// import CrmStatisticsCard from './CrmStatisticsCard.vue'
import CrmTotalSales from './CrmTotalSales.vue'
// import CrmRevenueReport from './CrmRevenueReport.vue'
// import CrmActivityTimeline from './CrmActivityTimeline.vue'
// import CrmWeeklySales from './CrmWeeklySales.vue'
// import CrmCardUpgradePlan from './CrmCardUpgradePlan.vue'
// import CrmCardMeetingSchedule from './CrmCardMeetingSchedule.vue'
// import CrmCardDeveloperMeetup from './CrmCardDeveloperMeetup.vue'
import httpClient from '@/services/http.service'

export default {
  name: 'CrmDashboard',
  components: {
    StatisticsCardWithImages,
    // StatisticsCardAreaChart,
    // StatisticsCardVertical,

    // CrmStatisticsCard,
    CrmTotalSales,
    // CrmRevenueReport,
    // CrmActivityTimeline,
    // CrmWeeklySales,
    // CrmCardUpgradePlan,
    // CrmCardMeetingSchedule,
    // CrmCardDeveloperMeetup,
  },
  data(){
    return{
      sales: null,
      month: null,
      timeLast: null,
      dialog: false,
    }
  },
  computed:{
    sum(){
      return this.$store.getters.dashboardSum
    },
    count(){
      return this.$store.getters.dashboardCount
    },
    postbacksUpdatedAt(){
      return this.$store.getters.dashboardUpdatedAt
    },
  },
  watch:{
    postbacksUpdatedAt(v){
      this.timeLast = moment(v).fromNow()
    }
  },
  mounted() {
    httpClient.get('dashboard/month').then(({data})=>{
      this.month = data
    })
    this.interval = setInterval(() => this.timeLeft(this.postbacksUpdatedAt), 10000)
  },
  methods:{
    timeLeft(v){
      this.timeLast = moment(v).fromNow()
    },
  },
  setup() {
    // card with images
    const ratingsOptions = {
      statTitle: 'Доход за день',
      statistics: '13k',
      change: '+38%',
      chipText: 'Year of 2021',
      chipColor: 'primary',
      avatar: require('@/assets/images/avatars/9.png'),
      avatarWidth: '111',
    }
    const sessionsOptions = {
      statTitle: 'Sessions',
      statistics: '24.5k',
      change: '-22%',
      chipText: 'Last Week',
      chipColor: 'secondary',
      avatar: require('@/assets/images/avatars/10.png'),
      avatarWidth: '86',
    }

    const growthAreaChart = {
      statTitle: 'Total Growth',
      statistics: '42.5k',
      series: [
        {
          name: 'Subscribers',
          data: [28, 40, 36, 52, 38, 60, 55],
        },
      ],
    }

    // vertical card options
    const newProjectOptions = {
      statTitle: 'New Project',
      icon: mdiLabelVariantOutline,
      color: 'primary',
      subtitle: 'Yearly Project',
      statistics: '862',
      change: '-18%',
    }

    return {
      ratingsOptions,
      sessionsOptions,
      growthAreaChart,
      newProjectOptions,
    }
  },
}
</script>
