<template>
  <v-card v-if="loaded">
    <v-card-text>
      <vue-apex-charts
        height="350"
        :options="chartOptions"
        :series="chartData"
        type="bar"
      ></vue-apex-charts>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiDotsVertical, mdiChevronUp } from '@mdi/js'

import { getVuetify } from '@core/utils'
import httpClient from '@/services/http.service'
import moment from 'moment'

export default {
  components: {
    VueApexCharts: () => import('vue-apexcharts'),
  },
  data() {
    return {
      loaded: false,
      chartData: [{
        name: 'Доход за месяц из AEP',
        data: []
      }],
      chartOptions: {
        colors: [this.$vuetify.theme.currentTheme.primary],
        chart: {
          height: 350,
          type: 'bar',
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          }
        },
        dataLabels: {
          enabled: true,
          formatter(val) {
            return `$${val.toLocaleString()}`
          },
          offsetY: -30,
          style: {
            fontSize: '16px',
            colors: ['#ffffff']
          }
        },

        xaxis: {
          categories: [],
          position: 'top',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: true,
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter(val) {
              return `$${val.toLocaleString()}`
            }
          }

        },
        title: {
          text: 'Доходы за последние 6 месяцев',
          floating: true,
          offsetY: 330,
          align: 'center',
          style: {
            color: '#444'
          }
        }
      },
    }
  },
  mounted() {
    httpClient.get('dashboard/month').then(({data})=>{
      this.loaded = true
      this.month = data
      this.chartOptions.xaxis.categories = data.months
      this.chartData[0].data = data.sums
    })
  },

  // setup() {
  //   const $vuetify = getVuetify()
  //   return {
  //     chartOptions,
  //     chartData,
  //
  //
  //   }
  // },
}
</script>
